import { PrismicNextLink } from "@prismicio/next";

export const Link = (props) => {
  // const { as, url, link_type, children, ...rest } = props;
  // const Element = as || "a";

  // if (link_type === "Media" || link_type === "Web" || url) {
  //   if (!url) return null;
  //   return (
  //     <Element
  //       href={url}
  //       target={url?.includes("groundwork.nl") ? "_self" : "_blank"}
  //       rel="noopener noreferrer"
  //       {...rest}
  //     >
  //       {children}
  //     </Element>
  //   );
  // }

  if (props.isBroken) delete props.isBroken;

  return <PrismicNextLink linkResolver={linkResolver} {...props} />;
};

function linkResolver({ type, uid }) {
  if (type === "event") {
    return `/events/${uid}`;
  }

  if (type === "course_studyguide") {
    return `/opleidingen/${uid}/studiegids-aanvragen`;
  }

  if (type === "course_reviews") {
    return `/opleidingen/${uid}/reviews`;
  }

  if (type === "course_reviews_form") {
    return `/opleidingen/${uid}/reviews/formulier`;
  }

  if (type === "training_reviews") {
    return `/trainingen/${uid}/reviews`;
  }

  if (type === "training_reviews_form") {
    return `/trainingen/${uid}/reviews/formulier`;
  }

  return null;
}
