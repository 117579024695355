import { Link } from "components/Link";
import { MEDIUM, CYAN } from "shared/variables";
import cx from "classnames";
import styles from "./Button.module.css";

export const Button = ({
  as,
  children,
  color = CYAN,
  href,
  link,
  size = MEDIUM,
  ...rest
}) => {
  const classNames = cx(styles.button, styles[color], styles[size]);

  if (as) {
    const Element = as;
    return <Element className={classNames}>{children}</Element>;
  }

  if (link) {
    return (
      <Link className={classNames} field={link} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button type="submit" className={classNames} {...rest}>
      {children}
    </button>
  );
};
